
import { computed, defineComponent, ref, watch } from 'vue'
import { single } from '@/guides/endpoints/events'
import { useRoute } from 'vue-router'
import BackBtn from '@/components/BackBtn.vue'
import DropdownList from '@/components/DropdownList.vue'
import Error from '@/components/Error.vue'
import ArticleHeader from '@/components/article/Header.vue'
import Wysiwyg from '@/components/Wysiwyg.vue'

const availableFlexibles = {
  dropdown: DropdownList,
  wysiwyg: Wysiwyg,
}

export default defineComponent({
  name: 'eventDetails',
  components: {
    ArticleHeader,
    BackBtn,
    DropdownList,
    Error,
    Wysiwyg,
  },
  setup() {
    const data = ref()
    const route = useRoute()
    const slug = computed(() => route?.params?.slug)

    const fetch = () => {
      if (!slug.value) {
        return
      }

      single(slug.value).then(r => {
        data.value = r.data.content
      })
    }

    watch(() => slug.value, fetch)

    fetch()

    return {
      availableFlexibles,
      data,
    }
  },
})
